* {
  padding: 0;
  margin: 0;
}

nav {
  background-color: #250036;
  font-size: 25px;
  color: white;
  font-family: Oldenburg, sans-serif;
  overflow: hidden;
}

nav .container {
  width: 80%;
  max-width: 1000px;
  align-items: center;
  justify-content: center;
  text-align: center;

}

ul {
  padding-left: 0;
  margin-bottom: 0;
}

nav .container ul li {
  display: inline-block;
  border-radius: 0px;
}

nav .container ul li a {
  display: inline-block;
  text-decoration: none;
  padding: 10px 20px;
  color: white;
}

nav .container ul li.active {
  background-color: #5f1575;
  border-radius: 30px;
  transition: 0.3s;
}

nav .container ul li.active a {
  color: #ffffff;
}

.nav-item:hover {
  color: #aaa;
  font-weight: bold;
  border-bottom: 1px dashed #999;
  border-top: 1px dashed #999;

}

.navlinks {
  padding: 0;
  margin: 0;
}

.sticky {
  position: fixed;
  top: 20%;
  left: 0px;
  z-index: 22;
  display: flex;
  flex-direction: column;
  background-color:rgba(44, 10, 37, 0.5);

    padding: 10px;
  border-radius: 0px 10px 10px 0px;
  box-shadow: 2px 2px 2px 2px black;
  
}

.sticky a{
  text-decoration: none;
  display: inline-flex;
  /* padding-top: 3px; */
  /* padding-bottom: 3px; */
  }

.sticky  i:hover{
transform: scale(1.2);
transition: .5s ease;
}


.sticky i{
  transform: scale(1);
transition: .5s ease;
padding: 8px 8px 8px 0 ;
}

.sticky-info{
  display: none;
  font-size: 1.3rem;
  margin-left: 10px;
  justify-items: center;
  transition: 2s ease;

}

.sticky:hover .sticky-info{
display: block;
transition: 2s ease;

}


@media (max-width:1130px) {
  .navlinks {
    padding: 0;
    margin: 0 auto;
  }

}