
.portfolio {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 30%;
}

.image {
  position: relative;
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 1s ease;
  backface-visibility: hidden;
  border-radius: 90px;
  box-shadow: 2px 2px 1px 0px rgb(32, 23, 36);
}

.middle {
  transition: 0.25s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}



@media (max-width: 768px) {
  .container {
    width: 90%;
  }

  .middle {
    width: 90%;
    align-items: center;
    text-align: center;
    background-color: rgba(44, 10, 37, 0.342);
    border-radius: 50px;
    padding: 30px 10px;
    box-shadow: 0px 2px 20px 2px black;
  }

 
}
